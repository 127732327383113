// CSS For default dialog box
.Dialog {
	position: relative;
	&__root {
		min-width: 500px !important;
		border-radius: 12px !important;
		overflow: inherit !important;
	}
	font-weight: 600 !important;
	text-align: center;
	&__closeBtn {
		position: absolute !important;
		padding: 0px !important;
		top: -5px !important;
		right: -4px !important;
		// color: #000000 !important;
	}

	&__buttonGroup {
		width: 100%;
		padding: 0.5rem;
		text-align: center;
	}
	&__backButton {
		display: flex !important;
		color: var(--primary-theme) !important;
		font-size: 14px !important;
		align-items: center;
		margin: 0.5rem;
		height: 40px;
		padding: 0rem 0.5rem;
		cursor: pointer;
		border-radius: 6px;
		display: flex;
		position: absolute;
	}
	&__dialogContentRoot {
		padding: 0.5rem 1.5rem !important;
		margin: auto;
	}
	&__dialogContentRoot2 {
		padding: 15px !important;
		margin: auto;
	}
	&__titleRoot {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__titleRoot2 {
		width: 95%;
		font-size: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__deleteAccount{
		padding-top: 3rem !important;
		height: 4rem;
		background-color: #d32f2f;
		border-top-right-radius: 15px;
		border-top-left-radius: 15px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column !important;
	}
}

.Dialog .MuiDialogActions-root {
	justify-content: center !important;
}

.Dialog_backButton {
	width: 30px !important;
	height: 30px !important;
}

// Add Attribute Dialog box
.AddAttributeDialog {
	&__root {
		min-width: 506px !important;
		border-radius: 12px !important;
	}
	&__buttonGroup {
		width: 100%;
		text-align: center;
	}
	&__titleRoot {
		font-weight: 600 !important;
		font-size: 1rem;
	}
	&__closeBtn {
		position: absolute !important;
		padding: 0px !important;
		top: 0px !important;
		right: 0px !important;
	}
}

.AddAttributeDialog .MuiDialogActions-root {
	justify-content: center !important;
}

// .box{
//   color: #0000009f;
// }
