.filterModal {
	align-self: center;
	&__filterButtons {
		margin-top: 1rem;
		.Button__fontSize {
			border-radius: 15px !important;
		}
	}
	&__modalContainer {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 70vw;
		min-height: 60vh;
		padding: 1.8rem 2rem 1.4rem 2.4rem;
		border-radius: 20px;
		&__dimension {
			max-height: 38vh;
			overflow-y: scroll;
			padding: 0.5rem 0rem !important;
			&__dropdown {
				display: flex;
				.dropdown .MuiOutlinedInput-root {
					border-radius: 15px !important;
				}
				.input__defaultInput {
					border-radius: 15px !important;
				}
			}
		}
		&__dropdown {
			padding: 0.5rem;
			display: flex;
			align-items: center;
			.dropdown {
				flex-grow: 1;
			}
			.dropdownDark {
				flex-grow: 1;
			}
			.dropdown .MuiOutlinedInput-root {
				border-radius: 15px !important;
			}
			.dropdownDark .MuiOutlinedInput-root {
				border-radius: 15px !important;
			}
			// opacity: 0.9 !important;
		}
		.multi-select {
			max-width: 350px;
		}
	}
	&__closeBtn {
		position: absolute !important;
		padding: 0px !important;
		top: -4px !important;
		right: -6px !important;
		color: #000000 !important;
		svg {
			background-color: white;
			border-radius: 4rem;
		}
	}
	&__dimensionBlock {
		border: 0.5px solid grey;
		border-radius: 20px;
		padding: 2rem;
		max-height: 20rem;
		overflow: auto;
		&__conditions {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;
		}
	}
	&__filterAndAdv {
		height: 26rem;
		overflow: auto;
		padding: 0 20px;
	}
	&__excludeBlock {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		.input {
			width: inherit;
		}
		.input__defaultInput {
			border-radius: 15px !important;
		}
		&__chips {
			margin-top: 1rem;
			&__chipAlone {
				padding: 0.5rem;
				margin: 0.2rem !important;
			}
		}
	}
}
