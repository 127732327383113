.questionsDrawer {
	top: 0px;
	position: fixed;
	right: 0px;
	// transition: all 0.15s ease-out 0s;
	//min-height: 400px;
	width: 308px;
	// width: 100vw;
	// background-color: white;
	border-radius: 20px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	overflow-y: hidden;
	height: 100vh;
	overflow-x: hidden;
	::-webkit-scrollbar {
		width: 4px;
	}
}
.questions {
	// background-color: #fff;
	box-shadow: 0 25px 30px #0000001a;
	border-radius: 20px;
	padding: 10px;
	position: relative;
	left: 11px;
	position: relative;
	max-width: 283px !important;
	// border: 1px solid #bebebe;
	cursor: pointer;
	font-size: 0.8rem;
	// color: #171725;
	font-weight: 400;
}

.questions_container {
	top: 20px;
	border-radius: 0px;
	background-color: none;
	// background-color: white;
	width: 308px !important;
	// width: 100vw !important;
	right: 0px;
	left: 31px;
	//  border-top-left-radius: 20px;
	//   border-bottom-left-radius: 20px;
	height: auto;
	position: relative;
}

.backButtons {
	border: none !important;
	top: -6px;
}

.insightsText {
	left: 11px;
	position: relative;
	font-weight: bold;
	font-size: 20px;
	// color: black;
}

.headingContainer {
	box-shadow: 0 25px 30px #0000001a;
	min-width: 306px;
	margin-left: -16px;
	padding-top: 10px;
	// border-top: none !important;
}

.answerDrawer {
	padding-left: 1rem;
	//padding-top: 0.5rem;
	padding-top: 2rem;
	padding-bottom: 10px;
	.question {
		font-size: 1rem;
		// color: #171725;
		font-weight: 700;
	}
	.answer {
		// color: #171725;
		font-size: small;
		font-weight: 400;
	}
	.graphBorder {
		// background-color: white;
		box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.1019607843);
		border-radius: 20px;
		padding: 10px 2px 10px 10px;
		border: 1px solid #bebebe;
		height: 15em;
	}
	.graphName {
		// color: #171725;
		padding: 0 !important;
		margin-top: 0px;
		font-weight: bold;
		font-size: 14px;
	}
}
.questionsDrawer {
	.stackedChart {
		width: 100%;
		height: auto;
		box-shadow: unset;
		padding: 0;
	}
}
.dateRange {
	color: gray;
	font-size: 13px;
}
.highlight {
	font-weight: bold;
}

.MuiAvatar-circular > img {
	height: 20px;
	width: 20px;
}
.insightsButton {
	// background-color: white !important;
	box-shadow: none !important;
	.MuiButton-startIcon {
		margin-right: -2px;
		margin-left: 0;
	}
	&:hover {
		text-decoration: none;
		background-color: rgba(145, 73, 255, 0.04) !important;
	}
}

.insightsnoData {
	// color: #ffffff;
	font-size: 15px;
	font-weight: bold;
	fill: #ffffff;
	text-align: center;
	vertical-align: middle;
	line-height: 13em;
	// width: 16vw !important;
}
.questions {
	&:hover {
		box-shadow: #0000001a 0 4px 12px;
		transform: scale(1.05);
	}
}
