$text-link-transition: all 0.1s cubic-bezier(0.49, 0.99, 0.54, 0.98);

.dashboard {
	width: 100%;
	height: 100%;

	.highcharts-data-table thead tr,
	.highcharts-data-table tr:nth-child(even) {
		background: #f8f8f8;
	}

	.highcharts-data-table tr:hover {
		background: #f1f7ff;
	}

	// .highcharts-root {
	// 	.highcharts-background {
	// 		fill: transparent;
	// 	}
	// }
	.Dialog__titleRoot {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.highcharts-axis-line {
		stroke: none;
	}

	.Dialog {
		display: none !important;
	}
	.highcharts-title {
		width: 90% !important;
	}
	.highcharts-tooltip {
		z-index: 9999 !important;
	}
	.MuiPaper-root {
		cursor: grab;
	}
	.Dialog {
		cursor: grab;
	}
	.noData {
		width: 38vw;
		display: flex;
		justify-content: end;
		align-items: center;
		// font-size: 20px;
		// font-weight: 500;
		// color: #303030;
		font-size: 20px;
		// font-weight: bold;
		fill: #303030;
	}

	.roiBox {
		// background-color: white;
		box-shadow: 0px 25px 30px #0000001a;
		border-radius: 20px;
		padding: 10px 2px 10px 10px;
		// border-top-left-radius: 0px !important;
		// border: 1px solid #bebebe;
	}

	&__value {
		// .MuiTypography-root {
		//   font-size: 32px;
		// }
		font-size: 32px !important;
	}

	.datalabels {
		position: absolute;
		left: -160px;
	}

	&__value {
		// .MuiTypography-root {
		//   font-size: 32px;
		// }
		font-size: 32px !important;
		// color: var(--primary-theme);
	}

	.labels {
		font-size: 20px;
	}

	&__greenBox {
		height: 20px;
		width: auto;
		background-color: rgb(17, 203, 140, 0.3);
		padding: 2px;
		color: #1ba150;
		border-radius: 5px;

		&__span {
			margin-left: 0.5rem;
			font-size: 0.8rem;
			color: #1ba150;
			font-weight: 700;
		}

		&__img {
			width: 1.2rem;
			height: 0.8rem;
			margin-left: 0.5rem;
		}
	}

	&__redBox {
		height: 20px;
		width: auto;
		background-color: rgb(207, 20, 8, 0.3);
		color: #cf1408;
		padding: 2px;
		border-radius: 5px;

		&__span {
			color: #cf1408;
			margin-left: 0.5rem;
			font-size: 0.8rem;
			font-weight: 700;
		}

		&__img {
			width: 1.2rem;
			height: 0.8rem;
			margin-left: 0.5rem;
		}
	}

	&__greyBox {
		height: 20px;
		width: auto;
		background-color: #dcdcdc;
		color: black;
		padding: 2px;
		border-radius: 5px;

		&__span {
			color: black;
			margin-left: 0.5rem;
			font-size: 0.8rem;
			font-weight: 700;
		}
	}

	&__twoHeadings {
		padding: 1.5rem 1rem 0.8rem 1rem;
	}

	&__noDataFound {
		color: #868585;
		font-weight: 600;
	}

	&__section {
		padding: 0rem 1rem;

		&__heading {
			color: #171725;
			padding: 2rem 0rem 0rem;
		}
	}

	&__button-grid {
		cursor: pointer;
		padding: 0.9rem 2.5rem;
		margin-right: 1rem;
		margin-top: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		box-shadow: 0px 5px 12px #0000001a;
		border-radius: 50px;

		&:hover {
			background-color: var(--primary-theme);
			color: white;
			transition: 0.3s ease-in-out;
		}
	}

	&__heading {
		// color: #171725;
		opacity: 0.7;
	}

	&__percentInc {
		margin: auto !important;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 0.5rem;
		border-bottom: 1px solid #0000001a;
		padding-bottom: 1rem;
	}

	&__stats-grid {
		justify-content: center;
		margin: 1rem 0rem auto;
		margin-bottom: 2.5rem;
		// padding: 1rem;
		// background: var(--page-bg-color) 0% 0% no-repeat padding-box;
		border-radius: 20px;
	}

	&__salescard {
		background-color: white;
		box-shadow: 0px 25px 30px #0000001a;
		border-radius: 20px;
		padding: 0.7rem 0rem;
		border-top-left-radius: 0px !important;

		.container {
			width: 100%;
			// height: 100%;
		}
	}

	&__card {
		// background-color: white;
		box-shadow: 0px 25px 30px #0000001a;
		border-radius: 20px;
		padding: 0.7rem 0rem;
		border-top-left-radius: 0px !important;
		min-height: 60vh;

		.container {
			width: 100%;
			// height: 100%;
		}

		&__summary {
			// background: #ffffff 0% 0% no-repeat padding-box;
			// box-shadow: 0px 0px 26px #00000029;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
			position: relative;
			border-radius: 20px;
			padding: 15px;
			// border: 1px solid #bebebe;

			&__item {
				width: 100%;
				height: 30px !important;
				display: flex;
				align-items: center;

				.MuiTypography-root {
					font-size: 16px;
				}

				// padding-bottom: 2px;
			}

			&__bottom {
				background-color: #0000001a;
				opacity: 0.5;
				width: 100%;
				margin: auto;
			}

			&__circle {
				height: 15px;
				width: 15px;
				border-radius: 15px;
			}
		}

		.highcharts-container {
			width: 100% !important;
			// height: auto;
			// display: flex;
			margin: auto;
			// justify-content: flex-start;
		}

		// .highcharts-container {
		//   width: 100% !important;
		//   height: auto;
		//   display: flex;
		//   justify-content: center;
		// }
		.highcharts-root {
			width: 100% !important;
			// height: auto;
			display: flex;
			// margin: auto;

			.highcharts-background {
				fill: transparent;
			}
		}

		&__cardAlign {
			border-right: 1px solid #0000001a;
			padding: 0.5rem;
			margin: 0.5rem !important;
		}

		&__venn {
			&__label {
				font-family: Lato;
				min-width: 13rem;

				h5 {
					font-size: 20px !important;
				}
			}

			&__labelName0 {
				// left: 16px !important;
				// top: 173px !important;
			}

			&__labelName1 {
				// left: 656px !important;
				// top: 303px !important;
			}

			&__labelName2 {
				// left: 656px !important;
				// top: 53px !important;
			}
		}

		&__boxAlign {
			padding: 1.4rem 1rem;
			margin: 0.5rem !important;

			&__bottom {
				// background-color: #0000001a;
				// opacity: 0.5;
				width: 75%;
				margin: auto;
			}
		}
	}

	&__textalignWithVenn {
		border-right: 1px solid #0000001a;
		padding: 1rem;
	}

	// Percent
	&__percentageDiv {
		background-color: #cff5e8;
		border-radius: 5px;
		display: flex;
		align-items: center;
		width: fit-content;
	}

	&__arrowAlign {
		width: 1.5rem;
		padding: 0rem 0.4rem 0rem 0.4rem;
	}

	&__textAlign {
		font-style: italic;
		padding: 0rem 0.5rem 0rem 0rem;
	}

	&__lossDiv {
		background-color: #ffcfcd;
		border-radius: 5px;
	}

	&__profitDiv {
		background-color: #cff5e8;
		border-radius: 5px;
		align-items: center;
		text-align: center;
	}

	&__venn {
		// &__chart{

		// }
		&__responseData {
			padding: 0.7rem 0rem 0rem 0rem;
			align-items: center;
			justify-content: center;
			display: flex;
		}

		&__percentData {
			align-items: center;
			justify-content: center;
			display: flex;
			padding: 0.4rem 0rem 0rem 0rem;
		}

		&__totalRevenue {
			bottom: 5rem;
			right: 17.5rem;
		}

		&__totalRevenueDot {
			position: relative;
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			background-color: #c8a3ff;
			bottom: 21.2rem;
			left: 10rem;
		}

		&__totalRevenueLine {
			position: relative;
			bottom: 21rem;
			left: 10rem;

			&__img {
				width: 4.2rem;
			}
		}
	}

	&__vennData {
		// background-color: white;
		padding: 0.8rem;
		margin: 1rem 1rem !important;
		box-shadow: 0px 0px 26px #00000029;
		border-radius: 20px;

		&__cardPercentageValues {
			padding: 0rem 0.4rem 0rem 0.4rem;
		}

		&__increased {
			color: #2a8063;
		}

		&__decreased {
			color: #fa0f00;
		}
	}

	//
	&__table-view {
		box-shadow: 0 25px 30px rgb(0 0 0 / 10%);
		// margin:0 1rem;
		// padding: 5px;
		// background: #ffff;
		// position: relative;
		// margin-bottom: 3rem;
		width: 100%;
		// height: 100%;
		border-radius: 15px;

		.rdt_TableCell {
			// display: flex;
			// align-items: center;
			justify-content: center;

			-webkit-box-flex: 1;
			// flex: 1 0 0px;
			// max-width: 100%;
			// min-width: 100px;
		}

		.table_bottom_total {
			display: flex;
			// padding: 5px;
			overflow-x: hidden;
			width: 100%;

			.table-col-total {
				flex: 1;
				font-size: 14px;
				text-align: center;
				padding-top: 10px;
				padding-bottom: 10px;
				//  new
				position: relative;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				box-sizing: border-box;
				line-height: normal;
				padding-left: 10px;
				padding-right: 10px;
				word-break: break-word;
				font: normal normal medium 14px Lato;
				font-size: 14px !important;
				-webkit-letter-spacing: 0.13px;
				-moz-letter-spacing: 0.13px;
				-ms-letter-spacing: 0.13px;
				letter-spacing: 0.13px;
				height: 47px !important;
				min-width: 125px;
				justify-content: center;
				display: flex;
			}

			.table-col-total:last-child,
			:last-of-type {
				justify-content: center;
			}
		}

		.table_bottom_total::-webkit-scrollbar {
			width: 0.1em !important;
			height: 4px !important;
		}

		.table_bottom_total::-webkit-scrollbar-thumb {
			background-color: var(--primary-theme);
			visibility: hidden;
		}

		.Datatable {
			div:first-child {
				border-radius: unset;
			}
		}
	}

	&__table-view2 {
		// box-shadow: 0 25px 30px rgb(0 0 0 / 10%);
		// margin:0 1rem;
		padding: 5px;
		background: #ffff;
		width: 100%;
		// position: relative;
		// margin-bottom: 3rem;
		border-radius: 25px;

		:last-child {
			border: none;
		}
	}

	&__tableEllipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: center;
		white-space: nowrap;
	}

	// .highcharts-color-0 text {
	//   // stroke: #707070;
	//   font-family: lato;
	//   fill: #868585 !important;
	// }
}

.revenue {
	color: red !important;
	font-size: 32px;
	font-weight: 600;
}

.title {
	font-size: 18px;
	font-style: italic;
}

.flexBox {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;
}

.numericData {
	color: #000000;
	padding: 0.7rem 0rem 0rem 0rem;
	font-style: italic;
	align-items: center;
	justify-content: center;
	display: flex;
	max-width: 10rem;
}

.HighCharts__venn {
	// -webkit-transform:rotate(180deg);
	// -moz-transform: rotate(180deg);
	// -ms-transform: rotate(180deg);
	// -o-transform: rotate(180deg);
	// transform: rotate(180deg);
	// transform: scaleX(-1);
}

.highcharts-color-1 text {
	font-weight: 600 !important;
}

.container .highcharts-container .highcharts-root {
	width: 70%;
}

.container .highcharts-root .highcharts-series-group {
	pointer-events: none;
}

.dashboard__card__venn__label h5 {
	font-size: 1.1rem;
	// margin-left: 50px;
}

@media screen and (max-width: 1388px) {
	.container .highcharts-container .highcharts-root {
		width: 60% !important;
	}

	.dashboard__card__venn__label h5 {
		font-size: 1.1rem;
	}

	.dashboard__card__venn__labelName0 {
		font: 400;
		position: absolute !important;
		left: 0px !important;
	}

	.dashboard__card__venn__labelName1 {
		font: 400;
		position: absolute !important;
		left: 22rem !important;
		top: 1rem !important;
	}

	.dashboard__card__venn__labelName2 {
		font: 400;
		left: 355px !important;
	}
}

@media screen and (min-width: 1420px) {
	.dashboard__card__venn__labelName0 {
		font: 400;
		position: absolute !important;
		left: 0px !important;
	}

	.dashboard__card__venn__labelName1 {
		left: 440px !important;
		top: 45px !important;
	}

	.dashboard__card__venn__labelName2 {
		font: 400;
		left: 440px !important;
		top: 290px !important;
	}
}

@media screen and (min-width: 1550px) {
	.dashboard__card__venn__labelName0 {
		font: 400;
		position: absolute !important;
		left: 0px !important;
	}

	.dashboard__card__venn__labelName1 {
		left: 504px !important;
		top: 53px !important;
	}

	.dashboard__card__venn__labelName2 {
		font: 400;
		left: 504px !important;
		top: 303px !important;
	}
}

@media screen and (max-width: 1024px) {
	.dashboard__card__venn__labelName0 {
		font: 400;
		position: absolute !important;
		left: 0px !important;
	}

	.dashboard__card__venn__labelName1 {
		left: 8rem !important;
		top: 1rem !important;
	}

	.dashboard__card__venn__labelName2 {
		font: 400;
		left: 8rem !important;
		top: 303px !important;
	}
}

@media screen and (min-width: 1850px) {
	.container .highcharts-container .highcharts-root {
		width: 500px;
		margin-right: 3rem;
	}

	.dashboard__card__venn__labelName0 {
		font: 400;
		position: absolute !important;
		left: 5rem !important;
	}

	.dashboard__card__venn__labelName1 {
		left: 580px !important;
		top: 53px !important;
	}

	.dashboard__card__venn__labelName2 {
		font: 400;
		left: 580px !important;
		top: 303px !important;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1460px) {
	.dashboard {
		&__card {
			&__summary {
				&__item {
					.MuiTypography-root {
						font-size: 13px;
					}
				}
			}
		}
		.values {
			font-size: 13px;
		}

		&__greenBox {
			padding: 0px;

			&__span {
				font-size: 10px;
				margin-left: 2px;
			}

			&__img {
				width: 15px;
				height: 15px;
				margin-left: 2px;
			}
		}

		&__redBox {
			padding: 0px;

			&__span {
				font-size: 10px;
				margin-left: 2px;
			}

			&__img {
				width: 15px;
				height: 15px;
				margin-left: 2px;
			}
		}

		.labels {
			font-size: 14px;
		}
	}

	.areaChartChange {
		font-size: 10px !important;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.areaChartTitle {
		display: flex;
		width: 100%;
		flex-direction: row;
		font-size: 14px;
	}
	.DialogareaChartTitle {
		width: 470px !important;
		display: flex;
		flex-direction: row;
		font-size: 20px;
		justify-content: center;
		gap: 15px;
	}
}

@media screen and (min-width: 1460px) and (max-width: 1600px) {
	.dashboard {
		&__card {
			&__summary {
				&__item {
					.MuiTypography-root {
						font-size: 15px;
					}
				}
			}
		}

		&__greenBox {
			padding: 2px;

			&__span {
				font-size: 13px;
				margin-left: 2px;
			}

			&__img {
				width: 18px;
				height: 18px;
				margin-left: 0px;
			}
		}

		&__redBox {
			padding: 2px;

			&__span {
				font-size: 13px;
				margin-left: 2px;
			}

			&__img {
				width: 18px;
				height: 18px;
				margin-left: 0px;
			}
		}

		.labels {
			font-size: 17px;
		}
	}
}

.stackedChart {
	width: 100%;
	padding: 1rem 2rem 1rem 1.5rem;
	overflow: hidden;
	// background: var(--page-bg-color) 0% 0% no-repeat padding-box;
	box-shadow: 0px 25px 30px #0000001a;
	border-radius: 15px;

	&__dropdown {
		min-width: 12vw;
		padding: 0rem 0.5rem;

		.MuiInputLabel-root {
			transform: translate(14px, -1px) scale(1);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			top: 9px;
		}

		.MuiFormLabel-filled {
			transform: translate(10px, -16px) scale(0.75);
			// background-color: white;
			// color: #000;
			padding: 0 0.2rem;
		}
	}
}

.highcharts-figure,
.highcharts-data-table table {
	// min-width: 320px;
	// max-width: 700px;
	margin: 1em auto;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #bebebe !important;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	// max-width: 500px;
}

.highcharts-data-table caption {
	padding: 1em 0;
	font-size: 1.2em;
	color: #555;
	display: none;
}

.highcharts-data-table th {
	font-weight: 600;
	border: 1px solid #bebebe !important;
	padding: 0.5em;
	font-size: 12px !important;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
	padding: 0.5em;
	border: 1px solid #bebebe !important;
	font-size: 12px !important;
}

.giveHoverEffect {
	// border: 2px solid red;
	border-radius: 20px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		// border: 2px solid red;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		transform: scale(1.05);
		// opacity: 0.6;
	}
}

.giveHoverEffect2 {
	// border: 2px solid red;
	border-radius: 16px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		// border: 2px solid red;

		opacity: 0.6;
	}
}

.highcharts-label {
	.emptyVal_highphan {
		float: left !important;
	}
}

.arrow {
	width: 20px;
}

.increase {
	color: #2a8063;
}

.decrease {
	color: #fa0f00;
}

.areaChartChange {
	font-size: 13px !important;
	display: flex;
	align-items: center;
	gap: 10px;
}

.areaChartTitle {
	display: flex;
	flex-direction: inherit;
	justify-content: space-between;
	width: 100%;
}
.DialogareaChartTitle {
	width: 470px !important;
	display: flex;
	flex-direction: row;
	font-size: 20px;
	justify-content: center;
	gap: 15px;
}
// .animatedtitle {
// 	display: inline-block;
// 	text-decoration: none;
// 	position: relative;
// 	font-weight: 600;

// 	&:hover {
// 		padding-bottom: -1px;
// 		border-bottom-style: solid;
// 		border-bottom-width: 3.1px;
// 		width: fit-content;

// 		&:before {
// 			opacity: 1;
// 			transform: translateX(4px);
// 			text-decoration: none;
// 			transform-origin: 50% 50%;
// 			transform: scale(1);
// 			// transition: $text-link-transition 0.1s;
// 			background-size: 100% 2px;

// 		}

// 		&:after {
// 			width: 100%;
// 			transform: scaleX(0);
// 			text-decoration: none;
// 			transform-origin: 50% 50%;
// 			transition: $text-link-transition;
// 			transform: scaleX(1);
// 			transform-origin: bottom left;
// 		}
// 	}

// 	&:before {
// 		display: inline-block;
// 		content: '\2192';
// 		opacity: 0;
// 		position: absolute;
// 		top: -1px;
// 		right: -26px;
// 		font-size: 28px;
// 		line-height: 14px;
// 		border-radius: 50%;
// 	}

// 	&:after {
// 		content: '';
// 		display: block;
// 		height: 2px;
// 		margin-top: 2px;

// 		transition: 300ms;
// 		width: 100%;
// 		z-index: 0;
// 		transform-origin: 100% 0;
// 		transform: scaleX(1);
// 		// transition: $text-link-transition 0.1s;
// 	}
// }

.animatedTitle {
	font-weight: 600;
}
.title2 {
	font-weight: 700;
}

.animatedtitle {
	position: relative;
	display: inline-block;
	// border-bottom: 1px dotted black;
}

.animatedtitle .tooltiptext {
	visibility: hidden;
	width: 80px;
	background-color: #cecece;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	font-size: 12px;
	/* Position the tooltip */
	position: absolute;
	top: 20px;
	z-index: 1;
}

.animatedtitle:hover .tooltiptext {
	visibility: visible;
}

.flexBox {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0px;
	padding: 0px;
	gap: 0px;
}

.main_trend_graph_container {
	.dashboard__table-view {
		padding: unset !important;

		.Datatable .rdt_TableHeadRow {
			background-color: #f2e9ff;
		}
	}
}

.dashboard__section {
	.dashboard__table-view {
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 0px;
		margin-bottom: 2rem;

		.Datatable .rdt_TableHeadRow {
			background-color: #f2e9ff;
		}

		.MuiTypography-h4 {
			margin-top: 0px;
			padding-top: 20px;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			// background-color: #f2e9ff;
		}
	}
}

.dashboard__table-view {
	.rdt_TableBody {
		.rdt_TableRow:not(:last-of-type) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		}
	}
}

.dashboard,
.atg,
.ChannelAndSourcePerformance {
	.Datatable .rdt_Table {
		.rdt_TableHead {
			.rdt_TableHeadRow {
				.rdt_TableCol {
					-webkit-box-flex: 1;
					flex: 1 0 0px;
					// max-width: 100%;
					min-width: 109px;
					// min-width: 195px;
					background-color: #f2e9ff;
					justify-content: center;
				}
			}
		}
	}

	.DatatableDark .rdt_Table {
		.rdt_TableHead {
			.rdt_TableHeadRow {
				.rdt_TableCol {
					-webkit-box-flex: 1;
					flex: 1 0 0px;
					// max-width: 100%;
					min-width: 109px;
					justify-content: center;
				}
			}
		}
	}
}
