@import '../../utils/mixins.scss';

.input {
	&__defaultInput {
		border-radius: 25px !important;

		// .MuiInputBase-input {
		//   padding: 0.6rem;
		// }
	}
	
	input {
		// height: var(--input--height) !important;
		// font-size: 0.9rem;
	}

	.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled) {
		// transform: translate(18px, 12px) scale(1);
	}

	.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
		color: red;
	}
}

// For removing the button of Upload file
.input input[type='file']::-webkit-file-upload-button {
	visibility: hidden;
}

@media screen and (max-width: 1366px) {
	.input {
		.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled) {
			// transform: translate(18px, 10px) scale(1) !important;
		}
	}
}

.input input[type='number'] {
	-moz-appearance: textfield;
}

.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	-webkit-box-shadow: 0 0 0 100px transparent inset !important;
	// box-shadow: inset 0 0 20px 20px transparent;
	// -webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}
